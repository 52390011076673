import React, { useState } from 'react';
import logo from '../../Image/Logo2.png';
import {
  Flex,
  IconButton,
  Avatar,
  AvatarBadge,
  Box,
  Text,
  Icon,
  Link,
  Menu,
  MenuButton,
} from '@chakra-ui/react';
import {
  Home,
  HomeActive,
  MinieWallet,
  // EarnIt,
  // EarnItActive,
  Savings,
  SavingsActive,
  Logout,
  Account,
  AccountActive,
  MinieWalletActive,
  Youngster,
  YoungsterActive,
  MinieQuiz,
  MinieQuizActive,
} from '../../Icons/Leftbar';
import { FiArrowLeft } from 'react-icons/fi';
import './SideBar.css';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import axios from 'axios';
import { toast } from 'react-toastify';

export function NavItem({
  icon,
  title,
  active,
  navSize,
  link,
  color,
  textColor,
  borderColor,
  dataTut,
}) {
  const history = useNavigate();
  return (
    <Flex
      data-tut={dataTut}
      onClick={() => history(link)}
      mt={3}
      flexDir="column"
      w="100%"
      alignItems={navSize === 'small' ? 'center' : 'flex-start'}
    >
      <Menu>
        <Link
          backgroundColor={active && color}
          borderRight={active && `2px solid ${borderColor}`}
          p={'20px 20px 10px 20px'}
          borderRadius={2}
          _hover={{ textDecor: 'none', backgroundColor: '#E6F1FC' }}
          w={navSize === 'large' && '100%'}
        >
          <MenuButton w="100%">
            <Flex>
              <Icon
                as={icon}
                fontSize="sm"
                color={active ? '#0073E5' : '#0E0E0F'}
              />
              <Text
                fontSize="14px"
                color={active ? textColor : '#0E0E0F'}
                ml={5}
                display={navSize === 'small' ? 'none' : 'flex'}
              >
                {title}
              </Text>
            </Flex>
          </MenuButton>
        </Link>
      </Menu>
    </Flex>
  );
}

export default function Sidebar(props) {
  const [navSize, changeNavSize] = useState(
    sessionStorage.getItem('navSize')
      ? sessionStorage.getItem('navSize')
      : 'large'
  );
  const url = window.location.pathname.split('/')[1];
  const accountType = window.location.pathname.split('/')[2];
  const history = useNavigate();

  // React.useEffect(() => {
  //   if (window.innerHeight > 768 || window.innerWidth < 828) {
  //     changeNavSize('small');
  //   }
  // }, []);

  const logOut = () => {
    Swal.fire({
      icon: 'question',
      title: 'Sure you want to logout?',
      text: "Done spending money today and you'd like to move on to other stuff?",
      confirmButtonText: 'Yes, log me out',
      confirmButtonColor: '#0d60d8',
      showCancelButton: true,
      cancelButtonColor: 'black',
      cancelButtonText: 'No, let me stay',
    }).then(result => {
      if (result.isConfirmed) {
        toast.loading('Logging out, please wait...');
        axios({
          method: 'GET',
          url: process.env.REACT_APP_API_URL + '/logout',
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
            ContentType: 'application/json',
            Accept: '*/*',
          },
        })
          .then(() => {
            sessionStorage.removeItem('minieToken');
            sessionStorage.removeItem('userData');
            history('/login/');
            toast.dismiss();
          })
          .catch(error => {
            console.log(error);
            toast.dismiss();
            sessionStorage.removeItem('minieToken');
            sessionStorage.removeItem('userData');
            history('/login/');
          });
      } else {
        return;
      }
    });
  };

  return (
    <Flex
      pos="sticky"
      left="5"
      h="100vh"
      marginTop="2.5vh"
      borderRadius={navSize === 'small' ? '5px' : '10px'}
      w={navSize === 'small' ? '75px' : '220px'}
      flexDir="column"
      justifyContent="space-between"
    >
      <Flex
        flexDir="column"
        w="100%"
        alignItems={navSize === 'small' ? 'center' : 'flex-start'}
        as="nav"
      >
        <Flex
          mt={3}
          flexDir={navSize === 'small' ? 'reverse-column' : 'row'}
          w="100%"
          alignItems={navSize === 'small' ? 'center' : 'center'}
          justify="center"
        >
          <Box mr="30px" display={navSize === 'small' ? 'none' : 'flex'}>
            <img src={logo} alt="logo" />
          </Box>
          <IconButton
            background="none"
            _hover={{ background: 'none' }}
            icon={<FiArrowLeft />}
            onClick={() => {
              if (navSize === 'small') {
                changeNavSize('large');
                sessionStorage.setItem('navSize', 'large');
              } else {
                changeNavSize('small');
                sessionStorage.setItem('navSize', 'small');
              }
            }}
          />
        </Flex>
        <NavItem
          link={'/dashboard/' + accountType}
          borderColor={'blue'}
          textColor="#005FEB"
          color="#E6EFFD"
          active={url === 'dashboard' ? true : false}
          className="navItem"
          navSize={navSize}
          icon={url === 'dashboard' ? HomeActive : Home}
          title="Home"
        />
        <NavItem
          dataTut="reactour__wallet"
          link={'/wallet/' + accountType}
          borderColor={'purple'}
          textColor={'#7A58EF'}
          color="#F2EEFD"
          active={url === 'wallet' ? true : false}
          navSize={navSize}
          icon={url === 'wallet' ? MinieWalletActive : MinieWallet}
          title="Minie Wallet"
        />
        {/* <NavItem
          dataTut="reactour__earnit"
          link={'/earnit/' + accountType}
          borderColor={'darkCyan'}
          active={url === 'earnit' ? true : false}
          textColor={'#06AEDA'}
          color="#E6F7FB"
          navSize={navSize}
          icon={url === 'earnit' ? EarnItActive : EarnIt}
          title="EarnIT"
        /> */}

        {accountType === 'adult' && (
          <NavItem
            link={'/youngsters/adult'}
            borderColor={'blue'}
            textColor={'#005FEB'}
            color="#E6EFFD"
            active={url === 'youngsters' ? true : false}
            navSize={navSize}
            icon={url === 'youngsters' ? YoungsterActive : Youngster}
            title="Youngster Accounts"
          />
        )}

        {accountType === 'youngster' && (
          <NavItem
            link={'/save/' + accountType}
            borderColor={'pink'}
            textColor={'#D138F0'}
            color="#FAEBFE"
            active={url === 'save' || url === 'kolosave' ? true : false}
            navSize={navSize}
            icon={
              url === 'save' || url === 'kolosave' ? SavingsActive : Savings
            }
            title="Minie Savings"
          />
        )}

        <NavItem
          link={'/kahoot/' + accountType}
          borderColor={'green'}
          textColor={'#15B79E'}
          color="#F2EEFD"
          active={url === 'kahoot' ? true : false}
          navSize={navSize}
          icon={url === 'kahoot' ? MinieQuizActive : MinieQuiz}
          title="Minie Quiz"
        />

        <NavItem
          dataTut="reactour__account"
          link={'/account/' + accountType}
          borderColor={'blue'}
          textColor="#005FEB"
          color="#E6EFFD"
          active={url === 'account' ? true : false}
          navSize={navSize}
          icon={url === 'account' ? AccountActive : Account}
          title="Account"
        />
      </Flex>

      <Flex
        flexDir="column"
        w="100%"
        alignItems={navSize === 'small' ? 'center' : 'flex-start'}
        mb={4}
        h="50%"
      >
        <Flex mt={30} align="center" flexDir="column" pl={2}>
          <Flex flexDir="row" align="center" mb={4}>
            <Avatar mr={4}>
              {props.user && (
                <Avatar
                  name={props.user.firstName}
                  src={props.user.profileImage}
                />
              )}
              <AvatarBadge boxSize="0.9em" bg="green.500" />
            </Avatar>
            {props.user && (
              <Text
                as="p"
                align="center"
                size="md"
                fontWeight="600"
                display={navSize === 'small' ? 'none' : 'flex'}
              >
                {props.user.firstName} {props.user.lastName}
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex
          flexDir="column"
          display={navSize === 'small' ? 'none' : 'flex'}
          w="100%"
        >
          <div onClick={logOut}>
            <NavItem navSize={navSize} icon={Logout} title="Logout" />
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
}
